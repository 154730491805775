import $ from 'jquery'
import $Form from './forms'
import $PromoCodes from './promo-codes'
import getGaClientId from './get_ga_client_id'
import handleMentionMeLink from "./mention_me.coffee"

$(function () {

  if (typeof window.Application === 'undefined') {
    window.Application = {};
  }
  window.Application.request_in_progress = false;

  if (typeof window.Application.work_last_search === 'undefined') {
    window.Application.work_last_search = '';
  }


  window.Application.setError = function (control_or_group, error_content) {
    var $error, $group;
    $group = $(control_or_group);
    if (!$group.hasClass('form-group')) {
      $group = $group.closest('.form-group');
    }
    $group.addClass('has-error');
    $error = $group.find('.error');
    if ($error.length) {
      return $error.html(error_content);
    } else {
      return $group.append("<span class='error'>" + error_content + "</span>");
    }
  };
  window.Application.clearError = function (control_or_group) {
    var $error, $group;
    $group = $(control_or_group);
    if (!$group.hasClass('form-group')) {
      $group = $group.closest('.form-group');
    }
    $group.removeClass('has-error');
    $error = $group.find('.error');
    if ($error.length) {
      return $error.remove();
    }
  };

  /* booking details page */
  $('#show-quote-details').on('click', function (e) {
    return $('#show-quote-details').fadeOut(function () {
      return $('.quote-details').fadeIn();
    });
  });

  // MentionMe referral link
  $('#quote-page').on('click', '.js-mm-link', handleMentionMeLink);

  (function initializeAddressToggle($addressManual, $addressSelect, $showAddressLink, $bookingAddressSelect) {

    function showAddress(toShow, toHide, linkForText, textToFill) {
      return function () {
        toHide.slideUp(function () {
          toShow.slideDown();
        });
        linkForText.text(textToFill);
      };
    }

    var showManualAddress = showAddress($addressManual, $addressSelect, $showAddressLink, 'Select address from list');
    var showAddressSelect = showAddress($addressSelect, $addressManual, $showAddressLink, 'Fill in address manually');


    function toggleAddress() {
      if ($showAddressLink.text() === 'Fill in address manually') {
        showManualAddress();
        $bookingAddressSelect.prop('required', false);
      } else {
        showAddressSelect();
        $bookingAddressSelect.prop('required', true);
      }
    }
    $showAddressLink.on('click', toggleAddress);

  })($('#js-address-manual'), $('#js-select-address'), $('#js-show-address_fields'), $('#js-booking_address-select'));


  var $bookingAddressSelect = $('#js-booking_address-select');
  var $address_1 = $('#booking_customer_street_address');
  var $address_2 = $('#booking_customer_street_address_2');
  var $city = $('#booking_customer_city');
  var $manualAddressContainer = $('#js-address-manual');

  $bookingAddressSelect.on('change', function () {
    var selectValue = $(this).val() || '{}';
    var addressFromSelect = JSON.parse(selectValue);
    $city.val(addressFromSelect['city']);
    $address_1.val(addressFromSelect['address_1']);
    $address_2.val(addressFromSelect['address_2']);
    $manualAddressContainer.find('.error').remove();
  });


  window.captchaChecked = function () {
    $('#captcha-error-container').siblings('.error').remove();
  };

  $('#booking_details_form').on('click', '#inspection-service-more-info-toggle', function (e) {
    var info_div;
    e.preventDefault();
    e.stopPropagation();
    info_div = $('#inspection-service-more-info');
    if (info_div.is(':visible')) {
      return info_div.slideUp();
    } else {
      return info_div.slideDown();
    }
  }).on('click', '.toggle-notes-for-mechanic', function (e) {
    e.preventDefault();
    return $(e.target).fadeOut("fast", function () {
      return $(e.delegateTarget).find(".notes-hidden-input").fadeIn("fast");
    });
  }).on('change', '#booking_can_be_collected', function (e) {
    if ($(e.currentTarget).is(":checked")) {
      return $('#booking_vehicle_drivable').parent().slideDown();
    } else {
      return $('#booking_vehicle_drivable').parent().slideUp();
    }
  });

  const collection_field = $('#booking_can_be_collected_true');
  if (collection_field.length && !collection_field.is(':checked')) {
    $('#safe-to-drive-question').hide();
  }

  $("input[name='booking[can_be_collected]']").on('change', function () {
    if (collection_field.is(':checked')) {
      $('#safe-to-drive-question').slideDown();
    } else {
      $('#safe-to-drive-question').slideUp();
    }
  });

  $('.yes-or-no-answer').on('click', function () {
    if (!$(this).hasClass('active')) {
      $(this).parents('.yes-or-no-question').find('.yes-or-no-answer').removeClass('active');
      $(this).addClass('active');
    }
  });

  window.Application.reloadWorkItems = function (url) {
    window.Application.stopReloadWorkItems = false;
    return $.poll(function (retry) {
      var data;
      data = {};
      if (window.Application.work_last_search.length) {
        data['work[work_description]'] = window.Application.work_last_search;
      }
      return $.get(url, data, function (response, status, xhr) {
        if ((status !== "success" || xhr.status === 202) && !window.Application.stopReloadWorkItems) {
          return retry();
        }
      });
    });
  };


  window.Application.waitForQuote = function (url, container) {
    window.Application.stopReloadWorkItems = true;
    return $.poll(function (retry) {
      return $.get(url, function (response, status, xhr) {
        if (status !== "success" || xhr.status === 202) {
          return retry();
        } else {
          $(container).html(response);
          var promoCodeForm = $('form.js-promotion-code-form');

          // Explicitly watch the promo code form, since it won't get caught on page load
          $Form.watchForm(promoCodeForm);


          // Handle form success
          $PromoCodes.handleForm();

        }
      }, 'html');
    });
  };


  // Quote page
  var promoCodeForm = $('form.js-promotion-code-form');
  if (promoCodeForm.length) {
    $PromoCodes.handleForm();
  }


  $('.page-booking').on('click', ".promotion-code-show a", function (event) {
    event.preventDefault();
    $('.promotion-code-show').addClass("hidden");
    return $(".js-promotion-code-form").removeClass("hidden");
  });

  /*  * TODO I think this is currently broken (.quote-summary should be .quote-page-container?) */
  $(".quote-summary").on("ajax:error", function (event) {
    var xhr = event.detail[2];
    if (xhr.status === 401) {
      return $(event.currentTarget).find('.alert-error').removeClass('hidden');
    }
  }).on("change", '.add-on', function (e) {
    var $this = $(e.target);
    $('.add-on').prop('disabled', true);
    $('.loading-spinner').removeClass('hidden');

    if ($this.is(':checked')) {
      $.post($this.attr('add_item_url'));
    } else {
      $.post($this.attr('remove_item_url'));
    }

  }).on("click", '.details-link', function (e) {
    return $('.add-on').prop('disabled', true);
  });

  // Booking forms GA tracking
  deferUntilLoaded.script(function() {
    const addGaTracking = function (gaClientId) {
      $('.booking_car_form, .booking-vrm-car-form, #booking_details_form, #booking-payment-form').each(function () {
        $(this).prepend('<input type="hidden" name="booking[ga_client_id]" value="' + gaClientId + '" />');
      });
    }
    getGaClientId(addGaTracking);
  })

  /* spinner when loading quote page */
  return $(".car-search-results").on('click', ".car-search-continue", function (event) {
    $(".car-search-continue").addClass("disabled");
    return $(".car-search-continue-spinner").removeClass("hidden");
  });
});
