import $ from 'jquery';
import $Form from './forms';
import $Translate from '../i18n/translations';

$(function () {
  // Handle car form submission
  var forms = $('.booking_car_form, .booking_vrm_car_form, .booking_ev_charger_form');

  forms.each(function () {
    var form = $(this),
      postcodeField = form.find('input[name="booking[customer_postcode]"]'),
      postcodeFieldType = postcodeField.attr('type'),
      postcodeFieldName = postcodeField.attr('name');


    function validateCarForm(noOfErrors) {
      var postCode = postcodeField.val().trim().replace(/  +/g, ' ').toUpperCase();
      if (/^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9A-Z][A-Z]{2}$/.test(postCode)) {
        form.trigger('custom-validation-callback', [noOfErrors]);
        return true;
      } else {
        $Form.showError(form, postcodeField, postcodeFieldType, postcodeFieldName, $Translate.putOut('Please enter your full postcode'));
        form.trigger('custom-validation-callback', [noOfErrors + 1]);
        return false;
      }
    }

    // Handle custom validation
    // This runs after all the other validation checks
    // The form will only submit after custom-validation-callback is triggered
    form.on('custom-validation', function (e, noOfErrors) {
      validateCarForm(noOfErrors);
    });

    // Toggle field views on /bookings/car
    var findBy = form.find("#booking_car_find_by");

    form.find('.change-view').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var link = $(this);
      if (link.hasClass('car-select-fields-show')) {
        findBy.val('fields').attr('data-require-fields', findBy.attr('data-fields-requires'));
        form.addClass('select-fields');
      } else {
        findBy.val('vrm').attr('data-require-fields', findBy.attr('data-vrm-requires'));
        form.removeClass('select-fields');
      }
    });
  });

  // Populate the car dropdowns
  function populateSelect(input, options) {
    input.find('option:gt(0)').remove();
    if (options.length === 1) {
      handleSingleOption(input, options[0]);
    } else {
      handleMultipleOptions(input, options);
    }
  }

  function handleMultipleOptions(input, options) {
    var options_html = "";
    options.forEach(function (option) {
      options_html += '<option value="' + option.toString().replace(/'/g, '&#39') + '">' + option + '</option>';
    });
    input.append(options_html);
    enableSelect(input);
  }

  function handleSingleOption(input, option) {
    input.append('<option value="' + option.toString().replace(/'/g, '&#39') + '">' + option + '</option>');
    if (option === '') {
      disableSelect(input);
    } else {
      input.val(option);
      enableSelect(input);
    }
    input.trigger('change');
  }

  function disableSelect(input) {
    input.prop('disabled', true).addClass('disabled').val('').parent().addClass('disabled');
  }

  function enableSelect(input) {
    input.removeClass('disabled').prop('disabled', false).parent().removeClass('disabled');
  }

  var car_field_selects = $('.js-car-select-fields select'),
    vehicle_tree = window.Application.vehicle_selections;

  car_field_selects.slice(0, -1).each(function (index, input) {
    $(input).on('change', function () {
      var next_inputs = car_field_selects.slice(index + 1);
      next_inputs.slice(1).each(function () {
        disableSelect($(this));
      });
      populateSelect(next_inputs.eq(0), options_for_select($(this)));
    });
  });

  function options_for_select(select) {
    var select_index = car_field_selects.index(select);
    var options = vehicle_tree;
    car_field_selects.slice(0, select_index + 1).each(function () {
      options = options[$(this).val()];
    });
    return options_array(options);
  }

  function options_array(object) {
    if ('start' in object && 'end' in object) {
      return range_array(object['start'], object['end']);
    } else {
      return Object.keys(object);
    }
  }

  function range_array(start, end) {
    var arr = [];
    for (var i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  }

  // Set up the car form
  window.Application.initialize_car_form = function (manufacturer, model, fuel, capacity, year) {
    var inputs = $('.js-car-select-fields select'),
      vehicle_tree = window.Application.vehicle_selections;

    populateSelect(inputs.eq(0), Object.keys(vehicle_tree));
    $.makeArray(arguments).forEach(function (attr, i) {
      if (typeof vehicle_tree == 'undefined') {
        return;
      }
      vehicle_tree = vehicle_tree[attr];
      if (attr == null || attr === '') {
        return;
      }
      inputs.eq(i).val(attr);
      var next_field = inputs.eq(++i);
      if (next_field.length) {
        populateSelect(next_field, options_array(vehicle_tree));
      }
    });
  };
});
