$("#booking_vehicle_drivable_false").click(function () {
    if ($(this).is(":checked")) {
        $("#recovery-message").slideDown().removeClass("hidden");
    }
});
$("#booking_vehicle_drivable_true").click(function () {
    if ($(this).is(":checked")) {
        $("#recovery-message").slideUp();
    }
});
